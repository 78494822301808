@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Noto+Sans+KR:wght@100..900&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Noto+Sans+KR:wght@100..900&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&display=swap');
.inter-font {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-variation-settings:
    "slnt" 0;
}
.inter-semilight{
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-variation-settings:
    "slnt" 0;

}
.inter-bold {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
  font-variation-settings:
    "slnt" 0;
}
.inter-light {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-variation-settings:
    "slnt" 0;
}
.pt-sans-regular {
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.pt-sans-semibold {
  font-family: "PT Sans", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.pt-sans-bold {
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.pt-sans-regular-italic {
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.pt-sans-bold-italic {
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
  font-style: italic;
}
.red-hat-display {
  font-family: "Red Hat Display", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}
.red-hat-display1{
  font-family: "Red Hat Display", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;

}
.red-hat-display2{
  font-family: "Red Hat Display", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;

}
.red-hat-display3{
  font-family: "Red Hat Display", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;

}
.red-hat-display4{
  font-family: "Red Hat Display", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;

}
.red-hat-display5{
  font-family: "Red Hat Display", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;

}
.red-hat-display6{
  font-family: "Red Hat Display", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;

}
.noto-sans {
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
  font-weight:100;
  font-style: normal;
}
.noto-sans1 {
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
  font-weight:200;
  font-style: normal;
}
.noto-sans2 {
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
  font-weight:300;
  font-style: normal;
}
.noto-sans3 {
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
  font-weight:400;
  font-style: normal;
}
.noto-sans4 {
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
  font-weight:500;
  font-style: normal;
}
.noto-sans5 {
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
  font-weight:600;
  font-style: normal;
}
.noto-sans6 {
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
  font-weight:900;
  font-style: normal;
}
.plus-jakarta-sans {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}
.border-dotted-b {
  border-bottom: 1px dotted rgb(169, 165, 165);
}
/* Loader.css */
.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}


.relative {
  position: relative;
}

.half-circle {
  width: 180px; /* Adjust based on your original div size */
  height: 26px; /* Adjust based on your original div size */
  clip-path: circle(90% at 100% 0); /* Cuts the bottom half */
  position: absolute;
  right:-25px; 
  top:2px/* Align to the left edge */
}

.main-part {
  overflow: hidden; /* Prevent content overflow during sliding */
}

.slide-up {
  max-height: 0; /* Hide the component */
  opacity: 0; /* Make it invisible */
  transition: max-height 0.3s ease, opacity 0.3s ease; /* Transition for sliding up */
}

.slide-down {
  max-height: 1000px; /* Allow the component to grow */
  opacity: 1; /* Make it visible */
  transition: max-height 0.3s ease, opacity 0.3s ease; /* Transition for sliding down */
}





.slider-container {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out; 
}

.slider-container.closed {
  opacity: 0;
  transform: translateY(-20px); 
  pointer-events: none; 
}
@media (min-width: 1024px) {
  .slider-container {
    transform: translateX(0); /* Default state */
  }

  .slider-container.closed {
    transform: translateX(-10px); /* Sliding horizontally when closed */
  }
}
.text-green-500 {
  fill: green; /* Set the color to green */
}






